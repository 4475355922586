import styles from "./styles.module.css";

import HeaderWrapper from "../../wrappers/HeaderWrapper/HeaderWrapper";
import AboutWrapper from "../../wrappers/AboutWrapper/AboutWrapper";
import VisionWrapper from "../../wrappers/VisionWrapper/VisionWrapper";
import NavigationButtons from "../../components/NavigationButtons/NavigationButtons";

const text = {
  firstLine: "OUR",
  secondLine: "IDENTITY",
  thirdLine: "IS OUR STRENGTH",
};

const OurIdentity = () => {
  return (
    <div className={styles.OurIdentity}>
      <HeaderWrapper text={text} height={"60vh"} fontSize={"5rem"} />
      <AboutWrapper />
      <VisionWrapper />
      <NavigationButtons
        nextText={"Management Team"}
        nextPage={"/management-team"}
        display={"none"}
      />
    </div>
  );
};

export default OurIdentity;
