import styles from "./styles.module.css";

import HeaderWrapper from "../../wrappers/HeaderWrapper/HeaderWrapper";
import BoardWrapper from "../../wrappers/BoardWrapper/BoardWrapper";
import NavigationButtons from "../../components/NavigationButtons/NavigationButtons";

const text = {
  firstLine: "BOARD",
  secondLine: "OF",
  thirdLine: "DIRECTORS",
};

const BoardOfDirectors = () => {
  return (
    <div className={styles.BoardOfDirectors}>
      <HeaderWrapper text={text} height={"60vh"} fontSize={"5rem"} />
      <BoardWrapper />
      <NavigationButtons
        nextText={"Our Works"}
        nextPage={"/our-works"}
        previousText={"Management Team"}
        previousPage={"/management-team"}
      />
    </div>
  );
};

export default BoardOfDirectors;
