import styles from "./styles.module.css";

// import roller from "../../media/images/roller.png";

const HeaderWrapper = ({ text, height, fontSize }) => {
  return (
    <div className={styles.HeaderWrapper} style={{ height: height }}>
      <h1 style={{ fontSize: fontSize }}>
        {text.firstLine} <br />
        <span>{text.secondLine}</span>
        <br />
        {text.thirdLine}
      </h1>
      {/* <div className={styles.HeaderWrapperImage}>
        <img src={roller} alt="Road" />
      </div> */}
    </div>
  );
};

export default HeaderWrapper;
