import styles from "./styles.module.css";

import Logo from "../../ui/Logo/Logo";

import { GoogleMap, MarkerF, useLoadScript } from "@react-google-maps/api";
import { useMemo } from "react";

import { FaPhone } from "react-icons/fa";
import { FaMapMarker } from "react-icons/fa";
import { FaMailBulk } from "react-icons/fa";
import { FaClock } from "react-icons/fa";

import { FaFacebook } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";

const Footer = () => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyCICYAwY25HzDVW5daQPkxOSOKxuudJ_GE",
  });

  const center = useMemo(
    () => ({
      lat: 9.072306,
      lng: 7.4682,
    }),
    []
  );

  return (
    <div className={styles.FooterWrapper}>
      <div className={styles.Top}>
        <div className={styles.About}>
          <h3>About</h3>
          <p style={{ textAlign: "justify" }}>
            Obtuse Tech Engineering & Construction LTD. has been serving
            Nigeria’s building and construction needs for years, with roots in
            BORNO, Kaduna, Kano, Kogi, Yobe, and Kwara states. Which continued
            its growth, expanding into numerous locations in the northeast,
            northwest and the whole country at large.
          </p>
          <div className={styles.SocialMedia}>
            <FaFacebook style={{ color: "#0B065E", fontSize: "30px" }} />
            <FaTwitter style={{ color: "#0B065E", fontSize: "30px" }} />
            <a href="https://instagram.com/">
              <FaInstagram style={{ color: "#0B065E", fontSize: "30px" }} />
            </a>
            <FaYoutube style={{ color: "#0B065E", fontSize: "30px" }} />
          </div>
        </div>
        <div className={styles.Contact}>
          <h3>Contact</h3>
          <p>
            <FaMapMarker style={{ color: "#0B065E" }} /> Obtuse Tech, Aminu Kano
            Crescent, Wuse 2, Abuja.
          </p>
          <p>
            <FaPhone style={{ color: "#0B065E" }} /> (081) 555-5555
          </p>
          <p>
            <FaMailBulk style={{ color: "#0B065E" }} />{" "}
            <a
              href="mailto:info@fayasschool.com"
              style={{ textDecoration: "none", color: "#292928" }}
            >
              info@obtuse-tech.com
            </a>
          </p>
          <p>
            <FaClock style={{ color: "#0B065E" }} /> Monday - Friday: 9:00am -
            5:00pm
          </p>
        </div>
        <div className={styles.Map}>
          {isLoaded && (
            <GoogleMap
              mapContainerStyle={{
                width: "100%",
                height: "100%",
                borderRadius: "25px",
              }}
              zoom={8}
              center={center}
            >
              <MarkerF position={center} />
            </GoogleMap>
          )}
        </div>
      </div>
      <div className={styles.Bottom}>
        <div className={styles.Logo}>
          <Logo />
          <p>Obtuse Engineering And Construction LTD. </p>
        </div>
        <p>
          Copyright © 2023. All rights reserved. Developed by Musaddiq Askira{" "}
        </p>
      </div>
    </div>
  );
};

export default Footer;
