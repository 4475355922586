import styles from "./styles.module.css";

const Paragraph = ({ text, color, shorten }) => {
  const shortenText = (text) => {
    if (text.length > 200) {
      return text.slice(0, 200) + "...";
    } else {
      return text;
    }
  };

  return (
    <div className={styles.Paragraph}>
      <p style={{ color: color }}>{shorten ? shortenText(text) : text}</p>
    </div>
  );
};

export default Paragraph;
