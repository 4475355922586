import style from "./styles.module.css";

import Heading from "../../ui/Heading/Heading";
import Paragraph from "../../ui/Paragraph/Paragraph";
import RegularLink from "../../components/RegularLink/RegularLink";

const AboutWrapper = () => {
  return (
    <div className={style.AboutWrapper}>
      <Heading text="WHO WE ARE." />
      <Paragraph
        text="OBTUSE TECH ENGINEERING AND CONSTRUCTIONS LIMITED is an indigenous
          company incorporated in 13th May 2013, RC: 1114389 with the goals to
          set new standards in construction industries/services delivery and
          human development within and outside the country."
      />
      <Paragraph
        text="Obtuse Tech Engineering and Constructions Limited was established by a
          group of minded individuals who all have great knowledge and
          experience in the field of Civil Engineering and human capacity
          development with the full plan to undertake on the specialized Civil
          Engineering works, such as construction/building infrastructure, roads
          and bridges, drainage, water supply, solar installation, production,
          trading, marketing, sales and distribution, import and export,
          consultancy services in general engineering, heavy civil engineering
          design such as factories projects and other related jobs."
      />
      <Paragraph
        text="With an equipment fleet worth hundreds of millions of Naira and a
        virtually unlimited bonding capacity, the Obtuse Tech Engineering
        and Constructions Limited provides the public and private sectors
        with a wide range of contracting services. Our production teams
        work closely with our safety, quality control and environmental
        departments to bring projects in on time and within budget, while
        meeting or exceeding all quality specifications and applicable
        regulations."
      />
      <Paragraph
        text="We look forward to provide a high level of customer service on your
        next project!"
      />
      <RegularLink text="Check Our Projects" link="/our-works" />
    </div>
  );
};

export default AboutWrapper;
