const client = [
  "Spectrum Engineering Limited",
  "Borno state Agricultural Development Programme",
  "Beach Stone Limited",
  "Borno State University, Maiduguri Borno State",
  "North East Development Commission (NEDC)",
  "Borno State Nigeria Erosion and Watershed management Project (NEWMAP)",
  "North East Multi-Sectoria Crises Recovery Peoject",
  "Medicins Sans Frontiers",
  "Borno State Ministry of Works",
  "Federal Ministry of Works and Housing",
  "Borno State Government",
  "Borno State Ministry of Works",
  "AGILE (World Bank Assisted project)",
];

export default client;
