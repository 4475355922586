import styles from "./styles.module.css";

import Input from "../../ui/Input/Input";
import Heading from "../../ui/Heading/Heading";

const FormWrapper = () => {
  return (
    <div className={styles.FormWrapper}>
      <Heading text={"MAKE AN INQUIRY"} />
      <Input text={"Subject"} placeholder={"Subject"} />
      <Input text={"Name"} placeholder={"Name"} />
      <Input text={"Email"} placeholder={"Email"} />
      <Input text={"Phone"} placeholder={"Phone"} />
      <Input text={"Message"} placeholder={"Message"} />
      <button className={styles.Button}>SEND</button>
    </div>
  );
};

export default FormWrapper;
