const team = [
  {
    id: 1,
    name: "Umar Sherrif Lawan",
    position: "Chief Executive Officer",
    image: require("../media/management-team/ceo.png"),
  },
  {
    id: 2,
    name: "Nuruddeen Sherrif Lawan",
    position: "Chief Finance Officer",
    image: require("../media/management-team/nuruddeen.png"),
  },
  {
    id: 3,
    name: "Healing Marcus Udo",
    position: "Secretary",
    image:
      "https://png.pngtree.com/png-vector/20220709/ourmid/pngtree-businessman-user-avatar-wearing-suit-with-red-tie-png-image_5809521.png",
  },
  {
    id: 4,
    name: "Muhammad Sherrif Lawan",
    position: "Civil Engieer",
    image: require("../media/management-team/muhammad.png"),
  },
];

export default team;
