import styles from "./styles.module.css";

import { useEffect, useState, useRef } from "react";

import { Link } from "react-router-dom";

import Logo from "../../ui/Logo/Logo";

import { FaMailBulk, FaSearch, FaBars, FaTimes } from "react-icons/fa";

import NavigationLink from "../../components/NavigationLink/NavigationLink";
import Search from "../../pages/Search/Search";

const about = [
  {
    id: 1,
    text: "Our Identity",
    path: "/our-identity",
  },
  {
    id: 2,
    text: "Management Team",
    path: "/management-team",
  },
  {
    id: 3,
    text: "Board Of Directors",
    path: "/board-of-directors",
  },
];

const projects = [
  {
    id: 1,
    text: "Our Works",
    path: "/our-works",
  },
  {
    id: 2,
    text: "Previous Clients",
    path: "/clients",
  },
  {
    id: 3,
    text: "Gallery",
    path: "/gallery",
  },
];

const NavigationWrapper = () => {
  const [isScrollingUp, setIsScrollingUp] = useState(true);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const [isSearchActive, setIsSearchActive] = useState(false);

  const sideNavRef = useRef();

  const closeOnOutsideClick = (event) => {
    if (isActive && !sideNavRef.current.contains(event.target)) {
      setIsActive(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", closeOnOutsideClick);

    return () => document.removeEventListener("mousedown", closeOnOutsideClick);
  }, [isActive]);

  const toggleNav = () => {
    setIsActive(!isActive);
  };

  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;

    setIsScrollingUp(currentScrollPos < prevScrollPos);
    setPrevScrollPos(currentScrollPos);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [prevScrollPos]);

  return (
    <div
      className={`${styles.NavigationWrapper} ${
        isScrollingUp ? "" : styles.NavigationWrapperHidden
      }`}
    >
      <Link
        className={styles.NavigationLogo}
        style={{ textDecoration: "none" }}
        to="/"
      >
        <Logo />
        <h2>OBTUSE TECH</h2>
      </Link>
      <div className={styles.NavigationIcons}>
        <div className={styles.OtherIcons}>
          <button
            className={styles.SearchIcon}
            onClick={() => setIsSearchActive(!isSearchActive)}
          >
            {isSearchActive ? (
              <FaTimes size={"24px"} color="#005A26" />
            ) : (
              <FaSearch size={"24px"} color="#005A26" />
            )}
          </button>
          <Link className={styles.MessageIcon} to="/contact">
            <FaMailBulk size={"24px"} color="#005A26" />
          </Link>
        </div>
        <div className={styles.MenuIcon}>
          <button onClick={toggleNav}>
            {isActive ? (
              <FaTimes size={"24px"} color="#005A26" />
            ) : (
              <FaBars size={"24px"} color="#005A26" />
            )}
          </button>
        </div>
        <div
          ref={sideNavRef}
          className={`${styles["side-nav"]} ${isActive ? styles.active : ""}`}
        >
          <NavigationLink
            links={about}
            text="About Obtuse Tech"
            setIsActive={setIsActive}
          />
          <NavigationLink
            links={projects}
            text="Projects"
            setIsActive={setIsActive}
          />
        </div>
      </div>
      {(isActive || isSearchActive) && (
        <div className={styles.NavigationOverlay}></div>
      )}
      {isSearchActive && <Search setIsSearchActive={setIsSearchActive} />}
    </div>
  );
};

export default NavigationWrapper;
