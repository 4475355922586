import styles from "./styles.module.css";

import HeaderWrapper from "../../wrappers/HeaderWrapper/HeaderWrapper";
import GalleryWrapper from "../../wrappers/GalleryWrapper/GalleryWrapper";
import NavigationButtons from "../../components/NavigationButtons/NavigationButtons";

const text = {
  firstLine: "GALLERY",
  secondLine: "OF",
  thirdLine: "PROJECTS",
};

const Gallery = () => {
  return (
    <div className={styles.Gallery}>
      <HeaderWrapper text={text} height={"60vh"} fontSize={"5rem"} />
      <GalleryWrapper />
      <NavigationButtons
        previousText={"Our Clients"}
        previousPage={"/clients"}
      />
    </div>
  );
};

export default Gallery;
