const road = [
  {
    id: "1",
    title: "Construction of 17KM Asphalt Road And Drainages.",
    image: [
      require("../media/images/road/road1.jpg"),
      require("../media/images/road/road2.jpg"),
      require("../media/images/road/road3.jpg"),
    ],
    link: "https://google.com",
    client: "Spectrum Engineering Limited",
    year: "September 2016",
    location: "Obehira-Adavi Ihima Obangede Road, Lokoja, Kogi State",
    description: `
The "Construction of 17km Asphaltic Road and Drainages" project, awarded by Spectrum Engineering Limited and executed by Obtuse Tech Engineering and Construction Limited, stands as a testament to the commitment to infrastructure development in Kogi State, Nigeria. This ambitious undertaking aimed to transform the connectivity and accessibility of the region by improving its road network.

Situated along the picturesque Obehira-Adavi Ihima Obangede Road in Lokoja, Kogi State, this project spanned a total length of 17 kilometers. The primary objective was to construct a durable and well-structured asphalt road capable of withstanding heavy traffic and adverse weather conditions. Additionally, the project included the implementation of an efficient drainage system to mitigate issues related to waterlogging and erosion.

The construction of this asphaltic road and drainage network commenced in September 2016, marking a significant milestone in the region's infrastructure development. The completion of this project not only enhanced transportation within Kogi State but also contributed to the overall socio-economic growth of the area.

This project showcases the collaborative efforts of Spectrum Engineering Limited and Obtuse Tech Engineering and Construction Limited in delivering high-quality infrastructure that benefits the local community, stimulates economic activity, and fosters regional progress.`,
  },
  {
    id: "2",
    title: "Construction of 10KM Asphalt Road and Drainages.",
    client: "Spectrum Engineering Limited",
    year: "February 2017",
    location: "Tsakuwa Dusan Kama Gata, Kano State",
    image: [
      require("../media/images/road/road4.jpg"),
      require("../media/images/road/road5.jpg"),
      require("../media/images/road/road6.jpg"),
      require("../media/images/road/road7.jpg"),
    ],
    link: "https://google.com",
    description: `
The "Construction of 10km Asphaltic Road and Drainages" project, commissioned by Spectrum Engineering Limited and executed by Obtuse Tech Engineering and Construction Limited, is a significant infrastructure development initiative in Kano State, Nigeria. This project, undertaken in February 2017, aimed to enhance transportation and connectivity in the region through the construction of a 10-kilometer stretch of asphaltic road and accompanying drainage systems.

Situated in the picturesque landscapes of Tsakuwa, Dusan Kama, and Gata within Kano State, this project was a crucial step toward improving the local infrastructure, promoting economic growth, and facilitating better accessibility for the community. The asphaltic road not only enhances the connectivity between these regions but also contributes to the overall development and prosperity of Kano State.

Throughout the construction process, Obtuse Tech Engineering and Construction Limited demonstrated its commitment to quality and precision, ensuring that the road and drainage systems were built to withstand the test of time and provide a reliable transportation network for the people of Kano State.

This project not only showcases Spectrum Engineering Limited's dedication to infrastructure development but also represents a collaborative effort between the public and private sectors to enhance the well-being of the community. The completion of the "Construction of 10km Asphaltic Road and Drainages" project stands as a testament to the positive impact of strategic infrastructure investments in the region, fostering economic growth and improving the quality of life for the residents of Kano State.`,
  },
  {
    id: "3",
    title: "Construction of Access Road and Culvert",
    client: "Borno state Agricultural Development Programme",
    year: "November 2017",
    location:
      "Bulablin ajiri and Musari Goni Kachallari, Jere LGA, Borno State",
    image: [
      require("../media/images/road/road3.jpg"),
      require("../media/images/road/road6.jpg"),
    ],
    link: "https://google.com",
    description: `
The "Construction of Access Road and Culvert" project, commissioned by the Borno State Agricultural Development Programme and executed by Obtuse Tech Engineering and Construction Limited, stands as a testament to the commitment to infrastructure development in the state.

This project, initiated in November 2017, focuses on improving critical access routes in the rural areas of Bulablin Ajiri and Musari Goni Kachallari, located in the Jere Local Government Area of Borno State. Accessible and well-maintained roads are pivotal for the socio-economic progress of any region, and this undertaking addresses precisely that need.

The scope of the project encompasses the construction of a durable and reliable access road network, along with the installation of a culvert system that aids in efficient drainage and flood control. These enhancements are vital for ensuring year-round accessibility, particularly during the rainy season when impassable roads can disrupt communities and agricultural activities.

The Construction of Access Road and Culvert project not only promotes the ease of transportation but also contributes to the overall development and well-being of the local populace. It enables the residents to connect with essential services, facilitates the transportation of agricultural produce to markets, and ensures that communities are resilient to environmental challenges.

As of November 2017, this project symbolizes a step forward in improving the quality of life for the residents of Bulablin Ajiri and Musari Goni Kachallari, making their daily lives more manageable and promising a brighter future for the region. The collaborative efforts of the Borno State Agricultural Development Programme and Obtuse Tech Engineering and Construction Limited reflect a commitment to fostering sustainable development and prosperity in Borno State.`,
  },
  {
    id: "4",
    title: "Construction of 30km Asphalt Road and Drainages",
    client: "Spectrum Engineering Limited",
    year: "June 2018",
    location: "Sumaila Falali, Birnin Bako, Kano State",
    image: [
      require("../media/images/road/road7.jpg"),
      require("../media/images/road/road2.jpg"),
    ],
    link: "https://google.com",
    description: `

The "Construction of 30km Asphaltic Road and Drainage" project, awarded by Spectrum Engineering Limited and developed by Obtuse Tech Engineering and Construction Limited, is a significant infrastructure development endeavor that played a pivotal role in enhancing transportation and connectivity in Kano State, Nigeria.

This ambitious project involved the construction of a 30-kilometer asphalt road, carefully designed to meet the highest standards of durability and safety. In addition to the roadwork, comprehensive drainage systems were put in place to effectively manage water flow, preventing flooding and ensuring the road's longevity.

Situated in Sumaila Falali, Birnin Bako, Kano State, this project addressed critical transportation needs in the region, improving accessibility for both residents and businesses. Completed in June 2018, it stands as a testament to the commitment of Spectrum Engineering Limited and the expertise of Obtuse Tech Engineering and Construction Limited in delivering high-quality infrastructure projects that positively impact local communities.

This 30km asphaltic road and drainage project not only facilitates smoother travel but also contributes to the socio-economic development of the area, promoting trade, commerce, and regional growth. Its successful completion underscores the importance of well-planned and executed infrastructure initiatives in fostering progress and connectivity within Nigeria's diverse landscape.`,
  },
];

export default road;
