import styles from "./styles.module.css";

import HeaderWrapper from "../../wrappers/HeaderWrapper/HeaderWrapper";
import WelcomeWrapper from "../../wrappers/WelcomeWrapper/WelcomeWrapper";
import ProjectIntroWrapper from "../../wrappers/ProjectIntroWrapper/ProjectIntroWrapper";

const text = {
  firstLine: "PAVING",
  secondLine: "PATHWAYS",
  thirdLine: "TO PROGRESS",
};

const Home = () => {
  return (
    <div className={styles.Home}>
      <HeaderWrapper text={text} />
      <WelcomeWrapper />
      <ProjectIntroWrapper />
    </div>
  );
};

export default Home;
