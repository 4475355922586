import styles from "./styles.module.css";


import Heading from "../../ui/Heading/Heading";
import Paragraph from "../../ui/Paragraph/Paragraph";
import ImageCard from "../../components/ImageCard/ImageCard";

import team from "../../api/team";

const TeamWrapper = () => {
  return (
    <div className={styles.TeamWrapper}>
      <Heading text="THE TEAM BEHIND OUR MANAGEMENT." />
      <Paragraph
        text={
          "At Obtuse Tech Engineering and Constructions Limited, our success is a testament to the exceptional individuals who form the heart of our team. We are a diverse group of experts, each bringing a unique set of skills and experiences to the table. From seasoned Civil Engineers to creative problem solvers, our team is united by a shared passion for excellence and a commitment to innovation."
        }
      />
      <Paragraph
        text={
          "As we introduce you to our team members, you'll discover the faces behind our achievements. Together, we collaborate, innovate, and strive for excellence in every project we undertake. Get to know the people who drive our success and make Obtuse Tech a leader in the construction industry."
        }
      />
      <Paragraph text={"Meet the team that transforms vision into reality."} />
      <div className={styles.Team}>
      
        {team.map((member) => (
          <ImageCard
            key={member.id}
            name={member.name}
            position={member.position}
            image={member.image}
          />
        ))}
      </div>
    </div>
  );
};

export default TeamWrapper;
