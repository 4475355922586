import styles from "./styles.module.css";

import HeaderWrapper from "../../wrappers/HeaderWrapper/HeaderWrapper";
import TeamWrapper from "../../wrappers/TeamWrapper/TeamWrapper";
import NavigationButtons from "../../components/NavigationButtons/NavigationButtons";

const text = {
  firstLine: "MANAGEMENT",
  secondLine: "TEAM",
};

const ManagementTeam = () => {
  return (
    <div className={styles.ManagementTeam}>
      <HeaderWrapper text={text} height={"60vh"} fontSize={"5rem"} />
      <TeamWrapper />
      <NavigationButtons
        nextText={"Board of Directors"}
        nextPage={"/board-of-directors"}
        previousText={"Our Identity"}
        previousPage={"/our-identity"}
      />
    </div>
  );
};

export default ManagementTeam;
