import styles from "./styles.module.css";

import HeaderWrapper from "../../wrappers/HeaderWrapper/HeaderWrapper";
import WorksWrapper from "../../wrappers/WorksWrapper/WorksWrapper";
import NavigationButtons from "../../components/NavigationButtons/NavigationButtons";

const text = {
  firstLine: "Our Works",
};

const OurWorks = () => {
  return (
    <div className={styles.OurWorks}>
      <HeaderWrapper text={text} height={"60vh"} fontSize={"5rem"} />
      <WorksWrapper />
      <NavigationButtons
        nextText={"Our Clients"}
        nextPage={"/clients"}
        previousText={"Board of Directors"}
        previousPage={"/board-of-directors"}
      />
    </div>
  );
};

export default OurWorks;
