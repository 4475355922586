import styles from "./styles.module.css";

import React from "react";

import Paragraph from "../../ui/Paragraph/Paragraph";
import Heading from "../../ui/Heading/Heading";

import { FaImages, FaArrowLeft, FaArrowRight, FaTimes } from "react-icons/fa";

const ProjectCard = ({
  title,
  description,
  images,
  client,
  year,
  location,
}) => {
  const [image, setImage] = React.useState(images[1]);
  const [isActive, setIsActive] = React.useState(false);

  const next = () => {
    const index = images.indexOf(image);
    if (index === images.length - 1) {
      setImage(images[0]);
    } else {
      setImage(images[index + 1]);
    }
  };

  const previous = () => {
    const index = images.indexOf(image);
    if (index === 0) {
      setImage(images[images.length - 1]);
    } else {
      setImage(images[index - 1]);
    }
  };

  React.useEffect(() => {
    const interval = setInterval(() => {
      next();
    }, 5000);
    return () => clearInterval(interval);
  }, [image]);

  return (
    <div className={styles.ProjectCard}>
      <div className={styles.Image}>
        <button className={styles.previous} onClick={previous}>
          <FaArrowLeft size={"24px"} color={"#fff"} />
        </button>
        <button className={styles.next} onClick={next}>
          <FaArrowRight size={"24px"} color={"#fff"} />
        </button>
        <img src={image} alt={title} />
        <button className={styles.Icon}>
          <FaImages size={"24px"} color={"#fff"} />
        </button>
      </div>
      <div className={styles.Content}>
        <Heading text={title} color="#005A26" />
        <Paragraph text={description} color="#000000" shorten={true} />
        <button
          className={styles.Button}
          onClick={() => setIsActive(!isActive)}
        >
          View Project
        </button>
      </div>

      {isActive && (
        <div className={styles.Overlay}>
          <div className={styles.Modal}>
            <button className={styles.Close} onClick={() => setIsActive(false)}>
              <FaTimes size={"24px"} color={"#323232"} />
            </button>
            <div className={styles.ImageModal}>
              <img src={image} alt={title} />
              <button className={styles.previous} onClick={previous}>
                <FaArrowLeft size={"24px"} color={"#fff"} />
              </button>
              <button className={styles.next} onClick={next}>
                <FaArrowRight size={"24px"} color={"#fff"} />
              </button>
              <button className={styles.Icon}>
                <FaImages size={"24px"} color={"#fff"} />
              </button>
            </div>
            <div className={styles.ContentModal}>
              <Heading text={title} color="#005A26" />
              <Paragraph text={description} color="#000000" />
            </div>
            <div className={styles.FooterModal}>
              <Paragraph text={`Client: ${client}`} />
              <Paragraph text={`Date: ${year}`} />
              <Paragraph text={`Location: ${location}`} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProjectCard;
