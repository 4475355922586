import styles from "./styles.module.css";

import clients from "../../api/clients";

const ClientCard = ({ client }) => {
  return (
    <div className={styles.ClientCard}>
      <p>{client}</p>
    </div>
  );
};

const ClientWrapper = () => {
  return (
    <div className={styles.ClientWrapper}>
      {clients.map((client) => (
        <ClientCard key={client.id} client={client} />
      ))}

      <div
        id="retainable-rss-embed"
        data-rss="https://medium.com/feed/retainable,
https://medium.com/feed/vue-mastery"
        data-maxcols="3"
        data-layout="grid"
        data-poststyle="inline"
        data-readmore="Read the rest"
        data-buttonclass="btn btn-primary"
        data-offset="-100"
      ></div>
    </div>
  );
};

export default ClientWrapper;
