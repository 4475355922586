const structural = [
  {
    title: "Construction of Hospital (ER/ICU)",
    client: "Medicins Sans Frontiers",
    year: "May 2020",
    location: "Gwange IV, Miaduguri, Borno State",
    image: [
      "https://i0.wp.com/businessday.ng/wp-content/uploads/2020/01/bituminous-road-construction.jpg?fit=675%2C367&ssl=1",
      "https://i0.wp.com/businessday.ng/wp-content/uploads/2020/01/bituminous-road-construction.jpg?fit=675%2C367&ssl=1",
      "https://i0.wp.com/businessday.ng/wp-content/uploads/2020/01/bituminous-road-construction.jpg?fit=675%2C367&ssl=1",
    ],
    link: "https://google.com",
    description: `
The "Construction of Hospital (ER/ICU)" project, commissioned by Medicins Sans Frontiers and executed by Obtuse Tech Engineering and Construction Limited, is a significant infrastructure development initiative in Borno State, Nigeria. This project, undertaken in May 2020, aimed to enhance the healthcare infrastructure in the region through the construction of a hospital with an emergency room and intensive care unit.
`,
  },

  {
    title: "Construction and Equipping of Primary Health Care Centre",
    client: "Federal Ministry of Works and Housing",
    year: "October 2020",
    location: "Monguno LGA Borno State",
    image: [
      "https://i0.wp.com/businessday.ng/wp-content/uploads/2020/01/bituminous-road-construction.jpg?fit=675%2C367&ssl=1",
    ],
    link: "",
    description: `
The "Construction and Equipping of Primary Health Care Centre" project, commissioned by the Federal Ministry of Works and Housing and executed by Obtuse Tech Engineering and Construction Limited, is a significant infrastructure development initiative in Borno State, Nigeria. This project, undertaken in October 2020, aimed to enhance the healthcare infrastructure in the region through the construction of a primary health care centre.
        `,
  },

];

export default structural;
