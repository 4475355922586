import styles from "./styles.module.css";

import HeaderWrapper from "../../wrappers/HeaderWrapper/HeaderWrapper";
import FormWrapper from "../../wrappers/FormWrapper/FormWrapper";

const text = {
  firstLine: "Get In Touch",
  // secondLine: "AN",
  // thirdLine: "INQUIRY",
};

const Contact = () => {
  return (
    <div className={styles.Contact}>
      <HeaderWrapper text={text} height={"60vh"} fontSize={"5rem"} />
      <FormWrapper />
    </div>
  );
};

export default Contact;
