import styles from "./styles.module.css";

import ClientWrapper from "../../wrappers/ClientWrapper/ClientWrapper";
import HeaderWrapper from "../../wrappers/HeaderWrapper/HeaderWrapper";
import NavigationButtons from "../../components/NavigationButtons/NavigationButtons";

const Clients = () => {
  return (
    <div className={styles.Clients}>
      <HeaderWrapper
        text={{
          firstLine: "OUR",
          secondLine: "CLIENTS",
        }}
        height={"60vh"}
        fontSize={"5rem"}
      />
      <ClientWrapper />
      <NavigationButtons
        nextText={"Gallery"}
        nextPage={"/gallery"}
        previousText={"Our Works"}
        previousPage={"/our-works"}
      />
    </div>
  );
};

export default Clients;
