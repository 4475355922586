import styles from "./styles.module.css";

import Heading from "../../ui/Heading/Heading";
import Paragraph from "../../ui/Paragraph/Paragraph";
import RegularLink from "../../components/RegularLink/RegularLink";

import automatic from "../../media/videos/automatic.mp4";

import { FaPlay } from "react-icons/fa";

const WelcomeWrapper = () => {
  return (
    <div className={styles.WelcomeWrapper}>
      <Heading text="Welcome To Obtuse Tech Engineering And Construction Limited." />
      <div className={styles.WelcomeWrapperText}>
        <Paragraph
          text="OBTUSE TECH ENGINEERING AND CONSTRUCTIONS LIMITED is an indigenous
          company incorporated in 13th May 2013, RC: 1114389 with the goals to
          set new standards in construction industries/services delivery and
          human development within and outside the country."
        />
        <Paragraph
          text="Obtuse Tech Engineering and Constructions Limited was established by a
          group of minded individuals who all have great knowledge and
          experience in the field of Civil Engineering and human capacity
          development with the full plan to undertake on the specialized Civil
          Engineering works, such as construction/building infrastructure, roads
          and bridges, drainage, water supply, solar installation, production,
          trading, marketing, sales and distribution, import and export,
          consultancy services in general engineering, heavy civil engineering
          design such as factories projects and other related jobs."
        />
      </div>

      <RegularLink text="More About Obtuse Tech" link="/our-identity" />
      <div className={styles.WelcomeWrapperVideo}>
        <video
          src={automatic}
          autoPlay
          loop
          muted
          controls
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
        />
      </div>
    </div>
  );
};

export default WelcomeWrapper;
