import styles from "./styles.module.css";

import { FaAngleDown, FaAngleUp } from "react-icons/fa";

import { useState } from "react";

import { Link } from "react-router-dom";

const NavigationLink = ({ links, text, setIsActive }) => {
  const [dropdown, setDropdown] = useState(false);

  const toggleDropdown = () => {
    setDropdown(!dropdown);
  };

  return (
    <div className={styles.NavigationLink}>
      <button className={styles.NavigationLinkText} onClick={toggleDropdown}>
        <p>{text}</p>
        {dropdown ? (
          <FaAngleUp color="#005A26" />
        ) : (
          <FaAngleDown color="#005A26" />
        )}
      </button>
      <div
        className={dropdown ? styles.NavigationLinkDropdown : styles.Inactive}
      >
        {links.map((link) => (
          <Link
            to={link.path}
            className={styles.NavigationLinkDropdownItem}
            key={link.id}
            style={{ textDecoration: "none" }}
            onClick={() => setIsActive(false)}
          >
            <p>{link.text}</p>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default NavigationLink;
