import styles from "./styles.module.css";

const Heading = ({ text, color, marginTop }) => {
  return (
    <div className={styles.Heading}>
      <h1 style={{ color: color, marginTop: marginTop }}>{text}</h1>
    </div>
  );
};

export default Heading;
