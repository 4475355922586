import "./App.css";

import { useEffect } from "react";

import NavigationWrapper from "./wrappers/NavigationWrapper/NavigationWrapper";
import FooterWrapper from "./wrappers/FooterWrapper/FooterWrapper";
import Home from "./pages/Home/Home";
import OurIdentity from "./pages/OurIdentity/OurIdentity";
import ManagementTeam from "./pages/ManagementTeam/ManagementTeam";
import BoardOfDirectors from "./pages/BoardOfDirectors/BoardOfDirectors";
import OurWorks from "./pages/OurWorks/OurWorks";
import Gallery from "./pages/Gallery/Gallery";
import Contact from "./pages/Contact/Contact";
import Clients from "./pages/Clients/Clients";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  useNavigate,
} from "react-router-dom";

const ScrollToTop = () => {
  const { pathname } = useLocation();
  const history = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname, history]);

  return null;
};

function App() {
  return (
    <div className="App">
      <Router>
        <ScrollToTop />
        <NavigationWrapper />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/our-identity" element={<OurIdentity />} />
          <Route path="/management-team" element={<ManagementTeam />} />
          <Route path="/board-of-directors" element={<BoardOfDirectors />} />
          <Route path="/our-works" element={<OurWorks />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/clients" element={<Clients />} />
        </Routes>
        <FooterWrapper />
      </Router>
    </div>
  );
}

export default App;
