import styles from "./styles.module.css";

import GalleryCard from "../../components/GalleryCard/GalleryCard";
import road from "../../api/road";
import structural from "../../api/structural";

const GalleryWrapper = () => {
  return (
    <div className={styles.GalleryWrapper}>
      {road.map((road) => {
        return <GalleryCard image={road.image[0]} text={"Road Construction"} />;
      })}
      {structural.map((structural) => {
        return (
          <GalleryCard
            image={structural.image[0]}
            text={"Structural Construction"}
          />
        );
      })}
    </div>
  );
};

export default GalleryWrapper;
