import styles from "./styles.module.css";

const ImageCard = ({ image, name, position }) => {
  return (
    <div className={styles.ImageCard}>
      <div className={styles.Image}>
        <img src={image} alt={name} />
      </div>
      <div className={styles.Content}>
        <h1>{name}</h1>
        <p>{position}</p>
      </div>
    </div>
  );
};

export default ImageCard;
