import styles from "./styles.module.css";

const Input = ({ text, placeholder }) => {
  return (
    <div className={styles.Input}>
      <p className={styles.InputText}>{text || "Subject"}</p>
      <input
        className={styles.InputField}
        type="text"
        placeholder={placeholder}
      />
    </div>
  );
};

export default Input;
