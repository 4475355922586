import styles from "./styles.module.css";

import road from "../../api/road";

import Heading from "../../ui/Heading/Heading";
import ProjectCard from "../../components/ProjectCard/ProjectCard";
import RegularLink from "../../components/RegularLink/RegularLink";

const ProjectIntroWrapper = ({ children }) => {
  return (
    <div className={styles.ProjectIntroWrapper}>
      <Heading text="Find Some Our Successfull Projects" />
      <div className={styles.ProjectIntro}>
        {road.slice(0, 2).map((item) => (
          <ProjectCard
            key={item.id}
            title={item.title}
            description={item.description}
            images={item.image}
            link={item.link}
          />
        ))}
      </div>
      <RegularLink text="More Projects" link="/our-works" />
    </div>
  );
};

export default ProjectIntroWrapper;
