import styles from "./styles.module.css";

import obtuseLogo from "../../media/logo/obtuseLogo.png";

const Logo = () => {
  return (
    <div className={styles.Logo}>
      <img src={obtuseLogo} alt="Obtuse Logo" />
    </div>
  );
};

export default Logo;
