import styles from "./styles.module.css";

import road from "../../api/road";
import structural from "../../api/structural";

import ProjectCard from "../../components/ProjectCard/ProjectCard";
import Heading from "../../ui/Heading/Heading";

import { useRef } from "react";

const tabs = [
  {
    title: "Road Constructions",
  },
  {
    title: "Structural Constructions",
  },
  {
    title: "Consultancy Services",
  },
  {
    title: "Supply of Merchandise",
  },
  {
    title: "Bridge Constructions",
  },
];

const TabWrapper = ({ scrollTo }) => {
  return (
    <div className={styles.TabWrapper}>
      {tabs.map((tab, index) => {
        return (
          <button className={styles.Tab} onClick={scrollTo}>
            <p
              style={
                index === 0
                  ? {
                      color: "#EA0119",
                      textShadow: "0px 0px 10px rgba(255, 255, 255, 1)",
                    }
                  : {}
              }
            >
              {tab.title}
            </p>
          </button>
        );
      })}
    </div>
  );
};

const WorksWrapper = () => {
  const headingRef = useRef(null);

  const scrollTo = () => {
    // Check if the ref is defined
    if (headingRef.current) {
      headingRef.current.scrollIntoView({
        behavior: "smooth", // You can change the scrolling behavior
        block: "start", // You can change the scroll position within the element
      });
    }
  };

  return (
    <div className={styles.WorksWrapper}>
      <TabWrapper scrollTo={scrollTo} />
      <div id={"scroll"}>
        <Heading
          text="Road Constructions"
          color="rgb(10, 120, 163)"
          marginTop={"100px"}
        />
      </div>
      <div className={styles.Projects}>
        {road.map((item) => {
          return (
            <ProjectCard
              title={item.title}
              description={item.description}
              images={item.image}
              link={item.link}
              client={item.client}
              year={item.year}
              location={item.location}
            />
          );
        })}
      </div>
      <div id={"scroll"}>
        <Heading
          text="Structural Constructions"
          color="rgb(10, 120, 163)"
          marginTop={"100px"}
          ref={headingRef}
        />
      </div>
      <div className={styles.Projects}>
        {structural.map((item) => {
          return (
            <ProjectCard
              title={item.title}
              description={item.description}
              images={item.image}
              link={item.link}
              client={item.client}
              year={item.year}
              location={item.location}
            />
          );
        })}
      </div>
    </div>
  );
};

export default WorksWrapper;
