import styles from "./styles.module.css";

const GalleryCard = ({ image, text }) => {
  return (
    <div className={styles.GalleryCard}>
      <img src={image} alt="gallery" />
      <div className={styles.GalleryCardText}>
        <p className={styles.GalleryText}>{text}</p>
      </div>
    </div>
  );
};

export default GalleryCard;
