import styles from "./styles.module.css";

import Heading from "../../ui/Heading/Heading";
import Paragraph from "../../ui/Paragraph/Paragraph";
import ImageCard from "../../components/ImageCard/ImageCard";

import board from "../../api/board";

const BoardWrapper = () => {
  return (
    <div className={styles.BoardWrapper}>
      <Heading text="OUR BOARD OF DIRECTORS, SEASONED IN THEIR ROLES, OFFER ROBUST STEWARDSHIP." />
      <Paragraph
        text={
          "Behind the vision and strategic direction of Obtuse Tech Engineering and Constructions Limited stands our esteemed Board of Directors. Comprising accomplished leaders from diverse backgrounds, our board brings a wealth of experience and expertise to guide our company's journey."
        }
      />
      <Paragraph
        text={
          "Each director is a beacon of knowledge, offering invaluable insights and wisdom. Together, they chart the course for our company's growth and sustainability, ensuring that we continue to set new industry standards."
        }
      />
      <Paragraph
        text={
          "Get to know the visionary minds who drive Obtuse Tech's success and share our unwavering commitment to excellence. Meet the individuals shaping our future and steering us toward greater heights."
        }
      />
      <div className={styles.Board}>
        {board.map((member) => (
          <ImageCard
            key={member.id}
            name={member.name}
            position={member.position}
            image={member.image}
          />
        ))}
      </div>
    </div>
  );
};

export default BoardWrapper;
