import styles from "./styles.module.css";
import { FaArrowRight } from "react-icons/fa";

import { Link } from "react-router-dom";

const RegularLink = ({ text, link }) => {
  return (
    <div className={styles.RegularLink}>
      <Link to={link} style={{ textDecoration: "none" }}>
        <span>{text}</span>
        <FaArrowRight size={"18px"} color="#333" />
      </Link>
    </div>
  );
};

export default RegularLink;
