const board = [
  {
    id: 1,
    name: "Umar Sherrif Lawan",
    position: "Board Chair",
    image: require("../media/management-team/ceo.png"),
  },
  {
    id: 2,
    name: "Ali Garga Bukar",
    position: "Board Memeber",
    image:
      "https://png.pngtree.com/png-vector/20220709/ourmid/pngtree-businessman-user-avatar-wearing-suit-with-red-tie-png-image_5809521.png",
  },
];

export default board;
