import styles from "./styles.module.css";

import { FaEye, FaBullseye } from "react-icons/fa";

import Heading from "../../ui/Heading/Heading";
import Paragraph from "../../ui/Paragraph/Paragraph";

const VisionWrapper = () => {
  return (
    <div className={styles.VisionWrapper}>
      <div className={styles.Vision}>
        <Heading text="Our Vision" color={"#fff"} />
        <Paragraph
          text="To succeed, every organization
            must envision the right priority it
            deserves; and therefore, our
            vision is encapsulated in our
            desire for building a strong
            foundation for the sustainable
            growth of the company and all
            that it undertakes through the
            proficient, efficient, veritable
            and impressive use of resultoriented
            human intellect and
            resources."
          color={"#fff"}
        />
        <FaEye size={"200px"} color="#fff" />
      </div>
      <div className={styles.Mission}>
        <FaBullseye size={"200px"} color="#fff" />
        <Heading text="Our Mission" color={"#fff"} />
        <Paragraph
          text="Our mission is to render value
adding services to our clients,
and to undertake such services
to their maximum satisfaction
through intellect, hard-work,
integrity and commitment."
          color={"#fff"}
        />
      </div>
    </div>
  );
};

export default VisionWrapper;
