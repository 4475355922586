import styles from "./styles.module.css";

import { Link } from "react-router-dom";

import { FaArrowRight, FaArrowLeft } from "react-icons/fa";

const NavigationButtons = ({
  previousPage,
  nextPage,
  previousText,
  nextText,
  display,
}) => {
  return (
    <div className={styles.NavigationButtons}>
      <Link
        to={previousPage}
        style={{ textDecoration: "none", display: display }}
      >
        <button className={styles.NavigationButtonsPrevious}>
          <FaArrowLeft size={"20px"} color="#005A26" />
          <p>Previous</p>
          <h1>{previousText}</h1>
        </button>
      </Link>
      <button className={styles.NavigationOverview}></button>
      <Link to={nextPage} style={{ textDecoration: "none" }}>
        <button className={styles.NavigationButtonsNext}>
          <p>Next</p>
          <FaArrowRight size={"20px"} color="#005A26" />
          <h1>{nextText}</h1>
        </button>
      </Link>
    </div>
  );
};

export default NavigationButtons;
