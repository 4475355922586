import styles from "./styles.module.css";

const Search = () => {
  return (
    <div className={styles.Search}>
      <div className={styles.SearchWrapper}>
        <h1>Search</h1>
      </div>
    </div>
  );
};

export default Search;
